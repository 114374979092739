import dayjs from 'dayjs';

// ページネーション設定: 表示件数
export const companyPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const companyPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '登録日', value: 'createdAt' },
    { label: '募集中のプロジェクト数', value: 'activeProjectCount' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const companyPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  createdAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
  activeProjectCount: {
    options: [
      { label: '多い', value: 'DESC' },
      { label: '少ない', value: 'ASC' },
    ],
  },
};

// ページネーション設定: 表示件数
export const joinedCompanyPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const joinedCompanyPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '登録日', value: 'createdAt' },
    { label: '権限', value: 'role' },
  ],
};

export const joinedCompanyPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  createdAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
};

// ページネーション設定: 表示件数
export const joinedCompanyMemberPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const joinedCompanyMemberPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [{ label: '参加日時', value: 'joinedAt' }],
};

export const joinedCompanyMemberPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  joinedAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
};

export const employeeCountTypes = {
  label: '社員規模',
  options: [
    { label: '1-5名', value: 1, min: 1, max: 5 },
    { label: '6-10名', value: 2, min: 6, max: 10 },
    { label: '11-20名', value: 3, min: 11, max: 20 },
    { label: '21-50名', value: 4, min: 21, max: 50 },
    { label: '51-100名', value: 5, min: 51, max: 100 },
    { label: '101-500名', value: 6, min: 101, max: 500 },
    { label: '501-1000名', value: 7, min: 501, max: 1000 },
    { label: '1000名以上', value: 8, min: 1001, max: -1 },
  ],
};

export const roles = {
  label: '権限',
  options: [
    { label: '所有者', value: 'PRIMARY_OWNER' },
    { label: '管理者', value: 'ADMINISTRATOR' },
    { label: '編集者', value: 'EDITOR' },
    { label: '投稿者', value: 'CONTRIBUTOR' },
    { label: '閲覧者', value: 'GENERAL' },
  ],
};

export const memberStatuses = {
  label: 'ステータス',
  options: [
    { label: '有効', value: 'ACTIVE' },
    { label: '一時休止', value: 'SUSPEND' },
    { label: '招待済', value: 'INVITED' },
  ],
};

export const memberTypes = {
  label: 'メンバータイプ',
  options: [
    { label: '社員', value: 'REGULAR' },
    { label: 'アルムナイ', value: 'ALUMNI' },
    { label: '外部パートナー', value: 'COLLABORATOR' },
  ],
};

export const companyEstablishmentOnYearTypes = {
  label: '設立年月',
  options: [...Array(255)]
    .filter((item, index) => {
      return index + 1900 <= dayjs().year() ? true : false;
    })
    .map((item, index) => {
      return {
        label: index + 1900,
        value: index + 1900,
      };
    }),
};

export const companyFinancialYearTypes = {
  label: '年度',
  options: [...Array(255)]
    .filter((item, index) => {
      return index + 1900 <= dayjs().year() ? true : false;
    })
    .map((item, index) => {
      return {
        label: index + 1900,
        value: index + 1900,
      };
    }),
};

export const memberBirthDayYearTypes = {
  label: '年度',
  options: [...Array(255)]
    .filter((item, index) => {
      return index + 1900 <= dayjs().year() ? true : false;
    })
    .map((item, index) => {
      return {
        label: index + 1900,
        value: index + 1900,
      };
    }),
};

export const companyMonthTypes = {
  label: '月',
  options: [...Array(12)].map((item, index) => {
    return {
      label: index + 1,
      value: index + 1,
    };
  }),
};

export const companyDayTypes = {
  label: '日',
  options: [...Array(31)].map((item, index) => {
    return {
      label: index + 1,
      value: index + 1,
    };
  }),
};

export const memberInvitationTypes = {
  label: '招待方法',
  options: [
    { label: 'メールアドレスで招待', value: 'EMAIL' },
    { label: 'ユーザーコードで招待', value: 'USER_CODE' },
  ],
};

export const memberSettingTypes = {
  label: '設定',
  options: [{ label: '一覧に表示', value: 'isExternalShow' }],
};

export const memberSettingExternalShowTypes = {
  label: '外部公開',
  options: [
    { label: '表示', value: true },
    { label: '非表示', value: false },
  ],
};

export const surveyTypes = {
  label: 'パートナー活用事例のシェア、インタビュー、などへ協力いただけますか？',
  options: [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
    { label: 'その他', value: 'OTHER' },
  ],
};

import React, { createContext, useEffect, useState } from 'react';
import useRequest from '@hooks/useRequest';
const stub = () => {
  throw new Error('forgot to wrap provider');
};
const initialState = {
  auth: stub,
  isLoggedIn: stub,
  appleSignIn: stub,
  googleSignIn: stub,
  facebookSignIn: stub,
  signIn: stub,
  signUp: stub,
  signOut: stub,
};
export const AuthContext = createContext(initialState);
const { Provider } = AuthContext;

export const AuthProvider = ({ auth: Auth, hub: Hub, children }) => {
  // ------------------------------------------
  // ステータスフラグ説明
  // フラグ1. isSignUp = アカウント登録（cognito）が完了しているか？
  // フラグ2. isSignIn = アカウント認証（ログイン）が完了しているか？
  // フラグ3.isRegistered = プロフィール登録が完了しているか?
  // ※ 理論上、フラグは1から順番にしかtrueにならない、できない
  // ------------------------------------------
  const [authState, setAuthState] = useState({
    isSignUp: false,
    isSignIn: false,
    isRegistered: false,
    isInActive: false,
  });
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);

  const updateAuthState = (type) => {
    switch (type) {
      case 'signIn':
        setAuthState({ ...authState, isSignUp: true, isSignIn: true });
        break;
      case 'signUp':
        setAuthState({
          ...authState,
          isSignUp: true,
          isSignIn: false,
        });
        break;
      case 'logIn':
        setAuthState({
          ...authState,
          isSignUp: true,
          isSignIn: true,
          isRegistered: true,
        });
        break;
      case 'logOut':
        setAuthState({
          ...authState,
          isSignUp: false,
          isSignIn: false,
          isRegistered: false,
        });
        break;
    }
  };

  // Auth functions
  const getAuth = () => Auth.currentAuthenticatedUser();
  const getSession = () => Auth.currentSession();
  const googleSignIn = () => {
    Auth.federatedSignIn({ provider: 'Google' });
    localStorage.setItem('syncRedirect', 1);
  };
  const facebookSignIn = () => {
    Auth.federatedSignIn({ provider: 'Facebook' });
    localStorage.setItem('syncRedirect', 1);
  };
  const appleSignIn = () => {
    Auth.federatedSignIn({ provider: 'SignInWithApple' });
    localStorage.setItem('syncRedirect', 1);
  };
  const userRequest = useRequest('/v1/auth/user', getSession);
  const configRequest = useRequest('/v1/config', getSession);
  // const organizationRequest = useRequest(`/v1/private/companies`, getSession);

  /**
   * サインイン: アカウントログイン処理 --> cognitoのアカウント認証
   * @param string  username
   * @param string pass
   */
  const signIn = async (username, pass) => {
    setAuthData({ username });
    await Auth.signIn(username, pass);
  };

  /**
   * サインアップ: アカウント登録 --> cognitoへのアカウント新規登録
   * @param string username
   * @param string pass
   * @param {*} attributes
   */
  const signUp = ({ username, password, attributes }) =>
    Auth.signUp({ username, password, attributes });

  /**
   * サインアウト: ログアウト
   */
  const signOut = () => Auth.signOut();

  /**
   * 認証ユーザーのPJDBへの登録情報取得
   * ※ cognito, PJDBへのプロフィール登録が完了して、PJDBが使用できるアプリログイン状態になる
   * このメソッドは、cognitoでアカウント認証された情報で、PJDBにプロフィール登録されているユーザー情報を検索する
   */
  const getUser = async () => {
    try {
      const res = await userRequest.get();
      setUserData(res.data);
      updateAuthState('logIn');
      return res;
    } catch (err) {
      // 410エラーの場合は、無効なアカウントの為、無効フラグを設定
      if (err.message.indexOf('410') !== -1) {
        setAuthState({
          ...authState,
          isSignUp: true,
          isSignIn: true,
          // isRegistered: true,
          isInActive: true,
        });
      }
    }
    return false;
  };

  /**
   * PJDBへのプロフィール登録（ユーザー登録）処理
   * @param object attributes
   */
  const register = async (payload) => {
    try {
      await userRequest.post(payload);
      await getUser();
    } catch (err) {
      // console.log(err)
    }
  };

  /**
   * cognitoの検証コード再送信
   * @param string username
   */
  const resendCode = (username) => Auth.resendSignUp(username);

  /**
   * cognitoの検証コードの正当性チェック
   * @param number code
   */
  const verifyCode = async (code: number, username: string) => {
    username =
      !username && authData && authData.username ? authData.username : username;

    await Auth.confirmSignUp(username, code);

    // ログイン画面に遷移させるために認証状態を更新 ( amplifyのサインインイベントにconfirmSinupがないみたいなので
    updateAuthState('logOut');
    setUserData(null);
    setAuthData(null);
    setConfigData(null);

    // コード認証画面に遷移した時、amplify上はログイン状態になっていない
    // await getAuth()
  };

  /**
   * パスワードを忘れた方へ用. 1
   * @param string username
   * @return boolean
   */
  const forgotPassword = async (username) => {
    await Auth.forgotPassword(username);
  };

  /**
   * パスワードを忘れた方へ用. 2
   * @param string username
   * @param number code
   * @param string newPassword
   * @return boolean
   */
  const forgotPasswordCode = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  /**
   * 閲覧組織モードの更新
   * @param {*} organization
   */
  const changeOrganization = (organization) => {
    setOrganizationData(Object.assign({}, organization));
  };

  // initialize
  const init = async () => {
    try {
      // ローディングフラグの設定: ON
      setLoading(true);

      // 全体設定を取得
      const config = await getConfig();
      setConfigData(config);

      // サインイン（cognito）の認証情報を取得
      const data = await getAuth();
      updateAuthState('signIn');
      setAuthData(data);

      // 登録情報を取得 (登録済みユーザーでログイン中に、画面を再読み込みした時用)
      await getUser();
    } catch (err) {
      // console.log('init error', err)
    } finally {
      // ローディングフラグの設定: OFF
      setLoading(false);
      // 1回でも読み込み完了したか？フラグON
      setIsLoaded(true);
    }
  };

  const updateConfigData = async () => {
    setConfigData(await getConfig());
  };

  // event listener
  useEffect(() => {
    const hubCallback = async ({ payload: { event, data } }) => {
      setLoading(true);
      switch (event) {
        case 'signIn':
          updateAuthState('signIn');
          setAuthData(data);
          await getUser();
          setConfigData(await getConfig());
          break;
        case 'signUp':
          updateAuthState('signUp');
          setAuthData(data.user);
          // setAuthData(data)
          break;
        case 'signOut':
          updateAuthState('logOut');
          setUserData(null);
          setAuthData(null);
          break;
        case 'signIn_failure':
          if (data.code === 'UserNotConfirmedException') {
            updateAuthState('signUp');
          } else {
            setAuthData(null);
            setUserData(null);
          }
          break;
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
          if (typeof data == 'undefined' || typeof data.code == 'undefined') {
            // console.log('authContext tokenRefresh, tokenRefresh_failure')
          } else if (data.code === 'UserNotConfirmedException') {
            updateAuthState('signUp');
            setUserData(null);
          } else if (data.code === 'UserNotFoundException') {
            updateAuthState('logOut');
            setAuthData(null);
            setUserData(null);
          }
          break;
      }
      setLoading(false);
    };
    Hub.listen('auth', hubCallback);
    init();
    return () => Hub.remove('auth', hubCallback);
  }, []);

  // -----------------------------------------------------------------------
  // debug用
  // useEffect(() => {
  //   console.log('state tracking in Auth context', authState, authData)
  // }, [authState, authData])
  // test
  // -----------------------------------------------------------------------

  /**
   * 各機能共通 & 変更性があるマスタデータを取得
   * @return Object|boolean
   */
  const getConfig = async () => {
    try {
      // 設定情報取得処理実行
      const res = await configRequest.get();

      // 設定情報オブジェクト初期化
      const config = {
        chargeOptions: {
          label: 'チャージオプション',
          options: res.data.chargeOptions
            ? res.data.chargeOptions.map((v) => {
                return {
                  id: v.id,
                  label: v.name,
                  value: v.id,
                  tag: v.tag,
                  price: v.price,
                  note: v.note,
                };
              })
            : [],
        },
        chargeProjectOptions: {
          label: 'チャージプロジェクトオプション',
          options: res.data.chargeProjectOptions
            ? res.data.chargeProjectOptions.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                  tag: v.tag,
                  price: v.price,
                  note: v.note,
                };
              })
            : [],
        },
        chargePlans: {
          label: '定期プラン',
          options: res.data.chargePlans
            ? res.data.chargePlans.map((v) => {
                return {
                  id: v.id,
                  label: v.name,
                  value: v.id,
                  tag: v.tag,
                  monthlyPrice: v.monthlyPrice,
                  yearlyPrice: v.yearlyPrice,
                  note: v.note,
                };
              })
            : [],
        },
        companyTags: {
          label: '企業タグ',
          options: res.data.companyTags
            ? res.data.companyTags.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                };
              })
            : [],
        },
        paymentTypes: {
          label: '決済方法',
          options: res.data.paymentTypes
            ? res.data.paymentTypes.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                };
              })
            : [],
        },
        languages: {
          label: '言語',
          options: res.data.languages
            ? res.data.languages.map((v) => {
                return {
                  code: v.code,
                  enLabel: v.enName,
                  label: v.name,
                  value: v.id,
                };
              })
            : [],
        },
        categories: [],
        locations: [],
        attachedCategories: [],
        attachedSkillCategories: [],
      };

      // ------------------------------------------
      // 特殊セレクター情報設定: categories ( プロジェクトカテゴリー )
      // ------------------------------------------
      if (res.data.categories && Array.isArray(res.data.categories)) {
        // 親カテゴリーデータを設定
        config.categories = res.data.categories
          .filter((v) => {
            return !v.parent.id ? true : false;
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            };
          });

        // 子カテゴリーデータを設定
        for (let i = 0; i < res.data.categories.length; i++) {
          // 親カテゴリーが存在しないカテゴリーは処理スキップ
          if (!res.data.categories[i].parent.id) continue;

          // 親カテゴリーの配列キーを取得
          const parentIndex = config.categories.findIndex(
            (v) => v.value === res.data.categories[i].parent.id
          );

          // 親カテゴリーの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue;

          // 親カテゴリーデータに小カテゴリーデータを設定
          config.categories[parentIndex].options.push({
            label: res.data.categories[i].name,
            value: res.data.categories[i].id,
          });
        }
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: categories ( プロジェクトカテゴリー )
      // ------------------------------------------
      if (
        res.data.attachedCategories &&
        Array.isArray(res.data.attachedCategories)
      ) {
        // 親カテゴリーデータを設定
        config.attachedCategories = res.data.attachedCategories
          .filter((v) => {
            return !v.parent.id ? true : false;
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            };
          });

        // 子カテゴリーデータを設定
        for (let i = 0; i < res.data.attachedCategories.length; i++) {
          // 親カテゴリーが存在しないカテゴリーは処理スキップ
          if (!res.data.attachedCategories[i].parent.id) continue;

          // 親カテゴリーの配列キーを取得
          const parentIndex = config.attachedCategories.findIndex(
            (v) => v.value === res.data.attachedCategories[i].parent.id
          );

          // 親カテゴリーの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue;

          // 親カテゴリーデータに小カテゴリーデータを設定
          config.attachedCategories[parentIndex].options.push({
            label: res.data.attachedCategories[i].name,
            value: res.data.attachedCategories[i].id,
          });
        }
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: skill categories ( スキルカテゴリー )
      // ------------------------------------------
      if (res.data.skillCategories && Array.isArray(res.data.skillCategories)) {
        // 親カテゴリーデータを設定
        config.skillCategories = res.data.skillCategories
          .filter((v) => {
            return !v.parent.id ? true : false;
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            };
          });

        // 子カテゴリーデータを設定
        for (let i = 0; i < res.data.skillCategories.length; i++) {
          // 親カテゴリーが存在しないカテゴリーは処理スキップ
          if (!res.data.skillCategories[i].parent.id) continue;

          // 親カテゴリーの配列キーを取得
          const parentIndex = config.skillCategories.findIndex(
            (v) => v.value === res.data.skillCategories[i].parent.id
          );

          // 親カテゴリーの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue;

          // 親カテゴリーデータに小カテゴリーデータを設定
          config.skillCategories[parentIndex].options.push({
            label: res.data.skillCategories[i].name,
            value: res.data.skillCategories[i].id,
          });
        }
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: skill categories ( スキルカテゴリー )
      // ------------------------------------------
      if (
        res.data.attachedSkillCategories &&
        Array.isArray(res.data.attachedSkillCategories)
      ) {
        // 親カテゴリーデータを設定
        config.attachedSkillCategories = res.data.attachedSkillCategories
          .filter((v) => {
            return !v.parent.id ? true : false;
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            };
          });

        // 子カテゴリーデータを設定
        for (let i = 0; i < res.data.attachedSkillCategories.length; i++) {
          // 親カテゴリーが存在しないカテゴリーは処理スキップ
          if (!res.data.attachedSkillCategories[i].parent.id) continue;

          // 親カテゴリーの配列キーを取得
          const parentIndex = config.attachedSkillCategories.findIndex(
            (v) => v.value === res.data.attachedSkillCategories[i].parent.id
          );

          // 親カテゴリーの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue;

          // 親カテゴリーデータに小カテゴリーデータを設定
          config.attachedSkillCategories[parentIndex].options.push({
            label: res.data.attachedSkillCategories[i].name,
            value: res.data.attachedSkillCategories[i].id,
          });
        }
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: locations
      // ------------------------------------------
      if (res.data.locations && Array.isArray(res.data.locations)) {
        // 親ロケーションーデータを設定
        config.locations = res.data.locations
          .filter((v) => {
            return !v.parent.id ? true : false;
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            };
          });

        // 子ロケーションデータを設定
        for (let i = 0; i < res.data.locations.length; i++) {
          // 親ロケーションが存在しないロケーションは処理スキップ
          if (!res.data.locations[i].parent.id) continue;

          // 親ロケーションの配列キーを取得
          const parentIndex = config.locations.findIndex(
            (v) => v.value === res.data.locations[i].parent.id
          );

          // 親ロケーションの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue;

          // 親ロケーションデータに小ロケーションデータを設定
          config.locations[parentIndex].options.push({
            label: res.data.locations[i].name,
            value: res.data.locations[i].id,
          });
        }
      }

      return config;
    } catch (err) {
      // 基本は例外がスローされる事がないため、console.logを出力
      // eslint-disable-next-line no-console
      // console.log(err)
    }
    return false;
  };

  return (
    <Provider
      value={{
        loading,
        isLoaded,
        authState,
        authData,
        userData,
        configData,
        updateConfigData,
        organizationData,
        Auth,
        appleSignIn,
        facebookSignIn,
        googleSignIn,
        signIn,
        signUp,
        signOut,
        verifyCode,
        resendCode,
        getUser,
        getConfig,
        getSession,
        init,
        register,
        forgotPassword,
        forgotPasswordCode,
        changeOrganization,
      }}
    >
      {children}
    </Provider>
  );
};

import dayjs from 'dayjs';

/**
 * ローカルストレージが実行可能か？を検証する処理
 * @param {boolean} isWriteCheck
 * @return boolean
 */
export const isLocalStorageEnabled = (isWriteCheck) => {
  // 書き込みチェックも行うかフラグを取得
  isWriteCheck = typeof isWriteCheck == 'undefined' ? true : false;

  // ローカルストレージオブジェクトが存在しない場合は、falseを返却
  if (typeof localStorage == 'undefined') return false;

  // 書き込みチェックをしない場合は、ローカルストレージオブジェクトにsetItemプロパティが存在する時点で、trueを返却
  if (!isWriteCheck && localStorage.getItem && localStorage.setItem)
    return true;

  // ローカルストレージの書き込みチェック
  try {
    // 書き込みチェック用キーで値を書き込み
    localStorage.setItem('_7JJbxY3YbwNAEAYUKYYPLYNH9ZgpUMDd', '1');

    // 書き込んだ値を取得でき、取得値も書き込んだ値と同一の場合は、trueを返却
    if (localStorage.getItem('_7JJbxY3YbwNAEAYUKYYPLYNH9ZgpUMDd') === '1') {
      localStorage.removeItem('_7JJbxY3YbwNAEAYUKYYPLYNH9ZgpUMDd');
      return true;
    }

    // 書き込んだ値と違う場合は、falseを返却
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * ローカルストレージへの保存処理
 * @param {string} key
 * @param {*} value
 * @returns
 */
export const setLocalStorageItem = (key, value) => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled()) return;

  // 保存値がオブジェクト形式ではない場合、処理終了
  if (typeof value != 'object') return;

  try {
    // valueオブジェクトをJSON文字列に変換して保存
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // JSON文字列に変換できなかった場合は、保存しない
    return;
  }
};

/**
 * ローカルストレージの保存値を取得
 * @param {string} key
 * @return {*}
 */
export const removeLocalStorageItem = (key) => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled()) return {};

  // ローカルストレージから指定キーの保存値を取得
  const data = localStorage.removeItem(key);

  // 指定キーのデータが空 or 存在しない場合は、空オブジェクトを返却
  if (!data) return {};

  try {
    // JSON文字列でデータを保存する仕組みなので、オブジェクト型に変換して返却
    return JSON.parse(data);
  } catch (e) {
    // オブジェクト型に変換できなかった場合は、空オブジェクトを返却
    return {};
  }
};

/**
 * ローカルストレージの保存値を削除
 * @param {string} key
 * @return {*}
 */
export const getLocalStorageItem = (key) => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled()) return {};

  // ローカルストレージから指定キーの保存値を取得
  const data = localStorage.getItem(key);

  // 指定キーのデータが空 or 存在しない場合は、空オブジェクトを返却
  if (!data) return {};

  try {
    // JSON文字列でデータを保存する仕組みなので、オブジェクト型に変換して返却
    return JSON.parse(data);
  } catch (e) {
    // オブジェクト型に変換できなかった場合は、空オブジェクトを返却
    return {};
  }
};

/**
 * ローカルストレージのクリア処理
 * @param {string} key
 * @return {*}
 */
export const clearLocalStorageOtherKey = (key) => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled()) return {};

  // ローカルストレージから指定キーの保存値を取得
  const data = localStorage.getItem(key);

  // ローカルストレージデータを全クリア
  window.localStorage.clear();

  // 指定キーのデータが空 or 存在しない場合は、空オブジェクトを返却
  if (!data) return {};

  try {
    // valueオブジェクトをJSON文字列に変換して保存
    localStorage.setItem(key, data);
  } catch (e) {
    // JSON文字列に変換できなかった場合は、保存しない
    return;
  }
};

// --------------------------------------------------------
// ログイン、認証コード送信など、再試行回数に制限をかける時用のストレージ操作
// --------------------------------------------------------
export const initFailedCount = (config, isReset) => {
  if (!config?.key || !config?.name) return false;

  // ローカルストレージデータ取得
  const storageData = getLocalStorageItem(config.key);

  // ログイン失敗回数が保存されていない場合は初期化
  if (!storageData?.[config.name]?.datetime || isReset) {
    storageData[config.name] = {
      count: 0,
      datetime: dayjs().valueOf(),
    };
    setLocalStorageItem(config.key, storageData);
    return;
  }

  // ログインの最終失敗日時を取得
  const failedDateTime = dayjs(storageData?.[config.name]?.datetime);
  const currentDateTime = dayjs();

  // ログイン日時が想定データではないか？24時間以上前の場合は、失敗履歴を初期化
  if (
    !failedDateTime.isValid() ||
    currentDateTime.diff(failedDateTime, 'minute') < 0 ||
    currentDateTime.diff(failedDateTime, 'minute') > 60 * 24
  ) {
    storageData[config.name] = {
      count: 0,
      datetime: dayjs().valueOf(),
    };
    setLocalStorageItem(config.key, storageData);
  }
};

export const getFailedCount = (config) => {
  if (!config?.key || !config?.name) return false;

  // ローカルストレージデータ取得
  const storageData = getLocalStorageItem(config.key);

  // ログイン失敗回数が保存されていない場合は初期化
  return storageData?.[config.name]?.count &&
    Number.isInteger(storageData?.[config.name]?.count)
    ? storageData?.[config.name]?.count
    : 0;
};

export const updateFailedCount = (config) => {
  if (!config?.key || !config?.name) return false;

  // ローカルストレージデータ取得
  const storageData = getLocalStorageItem(config.key);

  // ログイン失敗回数が保存されていない場合は初期化
  if (!storageData?.[config.name]?.datetime) {
    storageData[config.name] = {
      count: 1,
      datetime: dayjs().valueOf(),
    };
    setLocalStorageItem(config.key, storageData);
    return;
  }

  storageData[config.name] = {
    count:
      storageData?.[config.name]?.count &&
      Number.isInteger(storageData?.[config.name]?.count) &&
      storageData?.[config.name]?.count > 0
        ? storageData?.[config.name]?.count + 1
        : 1,
    datetime: dayjs().valueOf(),
  };

  setLocalStorageItem(config.key, storageData);
};

export const getStorageDataAsBase64 = () => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled())
    return Buffer.from(JSON.stringify({})).toString('base64');

  // get local storage data
  const data = {};
  [...Array(localStorage.length)].reduce((acc, _, index) => {
    const key = localStorage.key(index);
    const value = localStorage.getItem(key);
    acc[key] = value;
    return acc;
  }, data);

  return data && typeof data == 'object'
    ? Buffer.from(JSON.stringify(data)).toString('base64')
    : Buffer.from(JSON.stringify({})).toString('base64');
};

export const getStorageAuthorizedDataAsBase64 = () => {
  // ローカルストレージが使用できない環境の場合は、処理終了
  if (!isLocalStorageEnabled())
    return Buffer.from(JSON.stringify({})).toString('base64');

  // get local storage data
  const data = {};
  [...Array(localStorage.length)].reduce((acc, _, index) => {
    const key = localStorage.key(index);
    const value = localStorage.getItem(key);

    // Cognito関係の情報以外は破棄
    if (key.indexOf('CognitoIdentityServiceProvider') == -1) return acc;

    // Cognito関係の情報でも、不要な情報は廃棄
    if (
      key.search(/(.*)LastAuthUser$/) == -1 &&
      key.search(/(.*)idToken$/) == -1 &&
      key.search(/(.*)refreshToken$/) == -1
    )
      return acc;

    // キー名から不要なpreffixを削除
    const shortKeyName = key.split('.').slice(-1)[0];
    acc[shortKeyName] = value;
    return acc;
  }, data);

  return data && typeof data == 'object'
    ? Buffer.from(JSON.stringify(data)).toString('base64')
    : Buffer.from(JSON.stringify({})).toString('base64');
};

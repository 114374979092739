import dayjs from 'dayjs';

// ページネーション設定: 表示件数
export const userPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const userPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '登録日', value: 'createdAt' },
    { label: '最終ログイン日時', value: 'loginedAt' },
    // { label: 'おすすめ順', value: 'recommend' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const userPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  createdAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
  loginedAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
};

export const loginedStatusTypes = {
  label: '最終ログイン時間',
  options: [
    { label: 'ログイン中', value: 'ONLINE' },
    { label: '1日以内', value: 'WITHIN_1_DAY' },
    { label: '3日以内', value: 'WITHIN_3_DAY' },
    { label: '1週間以内', value: 'WITHIN_1_WEEK' },
    { label: '1週間以上前', value: '1_WEEK_AGO' },
    { label: '1ヶ月以上前', value: '1_MONTH_AGO' },
  ],
};

export const genderTypes = {
  label: '性別',
  options: [
    { label: '男性', value: 'MALE' },
    { label: '女性', value: 'FEMALE' },
    { label: 'その他', value: 'OTHER' },
    { label: '未回答', value: 'UNANSWERED' },
  ],
};

export const userWorkStatusTypes = {
  label: '稼働状況',
  options: [
    { label: '稼働可（今スグ）', value: 'WORKABLE_NOW' },
    { label: '稼働可（日付指定）', value: 'WORKABLE_AM' },
    { label: '稼働不可', value: 'DISWORKABLE' },
    { label: 'その他（応相談）', value: 'OTHER' },
  ],
};

// 検索BOXでのみ使用するユーザー稼働状況
// export const searchUserWorkStatusTypes = {
//   label: '稼働状況',
//   options: [
//     { label: '稼働可', value: 'WORKABLE_NOW' },
//     { label: 'その他', value: 'OTHER' },
//     { label: '稼働不可', value: 'DISWORKABLE' },
//   ],
// }

export const userScoutStatusTypes = {
  label: 'スカウト可否',
  options: [
    { label: 'スカウト可', value: true },
    { label: 'スカウト不可', value: false },
  ],
};

export const userSkillYearTypes = {
  label: '経験年数',
  options: [
    { label: '1年未満', value: 'Y1' },
    { label: '1年-3年未満', value: 'Y1_Y3' },
    { label: '3年-5年未満', value: 'Y3_Y5' },
    { label: '5年以上', value: 'Y5' },
  ],
};

export const userLanguageYearTypes = {
  label: '経験年数',
  options: [
    { label: '1年未満', value: 'Y1' },
    { label: '1年-3年未満', value: 'Y1_Y3' },
    { label: '3年-5年未満', value: 'Y3_Y5' },
    { label: '5年以上', value: 'Y5' },
  ],
};

export const userEmploymentTypes = {
  label: '就業状況',
  options: [
    { label: '会社所属', value: 'WORKER' },
    { label: 'フリーランス/個人事業主', value: 'FREELANCE' },
    { label: '学生', value: 'STUDENT' },
    { label: 'その他', value: 'OTHER' },
  ],
};

export const userBirthdayYearTypes = (() => {
  const currentYear = dayjs().year();
  const options = [...Array(101)].map((item, index) => {
    return {
      label: currentYear - index,
      value: currentYear - index,
    };
  });
  return {
    label: '生年月日（西暦）',
    options,
  };
})();

export const userCareersYearTypes = (() => {
  const currentYear = dayjs().year();
  const options = [...Array(101)].map((item, index) => {
    return {
      label: currentYear - index,
      value: currentYear - index,
    };
  });
  return {
    label: '経歴（西暦）',
    options,
  };
})();

export const userCertificatesYearTypes = (() => {
  const currentYear = dayjs().year();
  const options = [...Array(101)].map((item, index) => {
    return {
      label: currentYear - index,
      value: currentYear - index,
    };
  });
  return {
    label: '資格取得日（西暦）',
    options,
  };
})();

export const userMonthTypes = {
  label: '月',
  options: [...Array(12)].map((item, index) => {
    return {
      label: index + 1,
      value: index + 1,
    };
  }),
};

export const userDayTypes = {
  label: '日',
  options: [...Array(31)].map((item, index) => {
    return {
      label: index + 1,
      value: index + 1,
    };
  }),
};

export const userResidentStatusTypes = {
  label: '所属状況',
  options: [
    { label: '全て', value: 'ALL' },
    { label: '過去に所属', value: 'PAST' },
    { label: '現在所属', value: 'CURRENT' },
  ],
};

export const userConnectionStatusTypes = {
  label: 'ネットワーク状態',
  options: [
    { label: '全て', value: 'ALL' },
    { label: 'リクエスト中', value: 'IN_PROGRESS' },
    { label: '承諾', value: 'ACCEPTED' },
    { label: '未承諾', value: 'DECLINED' },
  ],
};

export const userConnectionTypes = {
  label: 'ネットワーク契機',
  options: [
    { label: '全て', value: 'ALL' },
    { label: '招待', value: 'INVITE' },
    { label: 'プロジェクト', value: 'PROJECT' },
    { label: '会社', value: 'COMPANY' },
    { label: 'リクエスト', value: 'REQUEST' },
  ],
};

// プロジェクト詳細画面の応募モーダル専用セレクター
export const userParticipationTypes = {
  label: '参画形態',
  options: [
    // 参画形態が、所属企業か？ビジネスページの場合は、OTHERにする
    // { label: '所属法人', value: 'AFFILIATION_COMPANY' },
    // { label: 'ビジネスページ', value: 'PARTICIPATION_COMPANY' },
    {
      label: '所属法人',
      isParticipationCompany: false,
      isAffiliationCompany: true,
      value: 'OTHER',
    },
    {
      label: 'ビジネスページ',
      isParticipationCompany: true,
      isAffiliationCompany: false,
      value: 'OTHER',
    },
    {
      label: '正社員',
      isParticipationCompany: false,
      isAffiliationCompany: false,
      value: 'GENERAL',
    },
    {
      label: '副業',
      isParticipationCompany: false,
      isAffiliationCompany: false,
      value: 'SIDE_BUSINESS',
    },
    {
      label: 'フリーランス/個人事業主',
      isParticipationCompany: false,
      isAffiliationCompany: false,
      value: 'FREELANCE',
    },
    {
      label: 'その他（契約社員、アルバイト等）',
      isParticipationCompany: false,
      isAffiliationCompany: false,
      value: 'OTHER',
    },
  ],
};

export const userInvitationTypes = {
  label: 'ユーザー招待',
  options: [
    { label: 'メールアドレスで招待', value: 'EMAIL' },
    { label: 'URLで招待', value: 'URL' },
  ],
};

export const userJoinedCompanySettingTypes = {
  label: '設定',
  options: [{ label: 'プロフィールに表示', value: 'isExternalShow' }],
};

export const userJoinedCompanySettingExternalShowTypes = {
  label: '外部公開',
  options: [
    { label: '表示', value: true },
    { label: '非表示', value: false },
  ],
};

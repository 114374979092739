import {
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAIL,
    CREATE_POST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAIL,
    PUBLIC_SAVE_DRAFT,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL,
    UPDATE_POST_FAIL,
    RESET_STATE,
} from "./actionTypes";

export const resetPosts = () => {
    return {
        type: RESET_STATE,
    };
};


export const getPosts = (res) => {
    return {
        type: GET_POSTS,
        payload: res,
    };
};

export const getPostsSuccess = (data) => {
    return {
        type: GET_POSTS_SUCCESS,
        payload: data,
    };
};

export const getPostsFail = (error) => {
    return {
        type: GET_POSTS_FAIL,
        payload: error,
    };
};

export const createPost = (data) => {
    return {
        type: CREATE_POST,
        payload: data,
    };
};

export const createPostSuccess = (data) => {
    return {
        type: CREATE_POST_SUCCESS,
        payload: data,
    };
};

export const createPostFail = (error) => {
    return {
        type: CREATE_POST_FAIL,
        payload: error,
    };
};

export const updatePost = (res) => {
    return {
        type: UPDATE_POST,
        payload: res,
    };
};

export const publicSaveDraft = (res) => {
    return {
        type: PUBLIC_SAVE_DRAFT,
        payload: res,
    };
};

export const updatePostSuccess = (data) => {
    return {
        type: UPDATE_POST_SUCCESS,
        payload: data,
    };
};

export const updatePostFail = (error) => {
    return {
        type: UPDATE_POST_FAIL,
        payload: error,
    };
};

export const deletePost = (res) => {
    return {
        type: DELETE_POST,
        payload: res,
    };
};

export const deletePostSuccess = (data) => {
    return {
        type: DELETE_POST_SUCCESS,
        payload: data,
    };
};

export const deletePostFail = (error) => {
    return {
        type: DELETE_POST_FAIL,
        payload: error,
    };
};

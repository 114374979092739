import { takeLatest, put, select } from 'redux-saga/effects';
import {
  CREATE_POST,
  GET_POSTS,
  UPDATE_POST,
  DELETE_POST,
  PUBLIC_SAVE_DRAFT,
} from './actionTypes';
import {
  getPostsSuccess,
  getPostsFail,
  // createPostSuccess,
  createPostFail,
  updatePostSuccess,
  updatePostFail,
  deletePostSuccess,
  deletePostFail,
  resetPosts,
} from './actions';
import { getPosts } from './selectors';

function* onGetPosts({ payload }) {
  try {
    const newPosts = Array.isArray(payload?.data.data)
      ? payload?.data.data
      : [];
    const currentPosts = yield select(getPosts);
    const posts = [...currentPosts, ...newPosts];

    yield put(
      getPostsSuccess({
        posts,
        currentPage: Number(payload?.data?.meta?.currentPage),
        lastPage: Number(payload?.data?.meta?.lastPage),
      })
    );
  } catch (error) {
    yield put(getPostsFail(error.response));
  }
}

function* onCreatePost({ payload }) {
  try {
    const currentPosts = yield select(getPosts);
    // eslint-disable-next-line no-unused-vars
    const posts = [payload, ...currentPosts];
    yield put(resetPosts());

    // yield put(createPostSuccess({
    //     post: payload,
    //     posts
    // }));
  } catch (error) {
    yield put(createPostFail(error.response));
  }
}

function* onUpdatePost({ payload }) {
  try {
    const currentPosts = yield select(getPosts);
    const posts = currentPosts.map((value) => {
      if (value.id === payload.id) {
        return payload;
      }

      return value;
    });

    yield put(
      updatePostSuccess({
        post: payload,
        posts,
      })
    );
  } catch (error) {
    yield put(updatePostFail(error.response));
  }
}

function* onPublicSaveDraft({ payload }) {
  try {
    const currentPosts = yield select(getPosts);
    let posts = currentPosts.filter((value) => value.id !== payload.post.id);

    if (payload.filter != 'DRAFT') {
      posts.unshift(payload.post);
    }

    yield put(
      updatePostSuccess({
        post: payload.post,
        posts,
      })
    );
  } catch (error) {
    yield put(updatePostFail(error.response));
  }
}

function* onDeletePost({ payload }) {
  try {
    const currentPosts = yield select(getPosts);
    const posts = currentPosts.filter((value) => value.id !== payload.id);

    yield put(
      deletePostSuccess({
        post: {},
        posts,
      })
    );
  } catch (error) {
    yield put(deletePostFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_POSTS, onGetPosts);
  yield takeLatest(CREATE_POST, onCreatePost);
  yield takeLatest(UPDATE_POST, onUpdatePost);
  yield takeLatest(DELETE_POST, onDeletePost);
  yield takeLatest(PUBLIC_SAVE_DRAFT, onPublicSaveDraft);
}

export default CartSaga;

import {
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAIL,
    CREATE_POST,
    CREATE_POST_SUCCESS,
    CREATE_POST_FAIL,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAIL, RESET_STATE,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL,
    PUBLIC_SAVE_DRAFT,
} from "./actionTypes";

const initialState = {
    posts: [],
    currentPage: 0,
    lastPage: null,
    post: {},
    isLoadingPost: false,
    isLoading: true,
    isError: false,
    error: {
        message: "",
    },
};

const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POSTS:
            state = { ...state, isLoading: true };
            break;
        case GET_POSTS_SUCCESS:
            state = { ...state, posts: action.payload.posts, currentPage: action.payload.currentPage,
                lastPage: action.payload.lastPage, isLoading: false };
            break;
        case GET_POSTS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isLoading: false,
                isError: true,
            };
            break;
        case CREATE_POST:
            state = { ...state, isLoading: true, isLoadingPost: true };
            break;
        case CREATE_POST_SUCCESS:
            state = { ...state, post: action.payload.post, posts: action.payload.posts, isLoading: false, isLoadingPost: false };
            break;
        case CREATE_POST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isLoading: false,
                isError: true,
                isLoadingPost: false
            };
            break;
        case PUBLIC_SAVE_DRAFT:
        case UPDATE_POST:
            state = { ...state, isLoading: true, isLoadingPost: true };
            break;
        case UPDATE_POST_SUCCESS:
            state = { ...state, post: action.payload.post, posts: action.payload.posts, isLoading: false, isLoadingPost: false };
            break;
        case UPDATE_POST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isLoading: false,
                isLoadingPost: false,
                isError: true,
            };
            break;
        case DELETE_POST:
            state = { ...state, isLoading: true, isLoadingPost: true };
            break;
        case DELETE_POST_SUCCESS:
            state = { ...state, post: action.payload.post, posts: action.payload.posts, isLoading: false, isLoadingPost: false };
            break;
        case DELETE_POST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isLoading: false,
                isLoadingPost: false,
                isError: true,
            };
            break;
        case RESET_STATE:
            state = { ...state, ...initialState };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default PostReducer;

import { mapKeysCamelCase, mapKeysSnakeCase } from '../mapKeyConvert';

export const requestBuilder = (session, option) => {
  if (!session) {
    throw Error('not auth');
  }

  if (option.data) {
    option.data = mapKeysSnakeCase(option.data);
  }
  if (option.params) {
    option.params = mapKeysSnakeCase(option.params);
  }

  option.headers = {
    ...option.headers,
    Authorization: session.idToken.jwtToken,
    'Content-Type': 'application/json',
  };

  return option;
};

// お問い合わせなど、匿名リクエスト専用
export const anonymouseRequestBuilder = (option) => {
  if (option.data) {
    option.data = mapKeysSnakeCase(option.data);
  }
  if (option.params) {
    option.params = mapKeysSnakeCase(option.params);
  }

  option.headers = {
    ...option.headers,
    'Content-Type': 'application/json',
  };

  return option;
};

export const responseBuilder = (res) => {
  return {
    status: res.status,
    headers: res.headers,
    data: mapKeysCamelCase(res.data),
  };
};

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";

export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const GET_POST_DETAILS_SUCCESS = "GET_POST_DETAILS_SUCCESS";
export const GET_POST_DETAILS_FAIL = "GET_POST_DETAILS_FAIL";

export const CREATE_POST = "CREATE_POST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL = "CREATE_POST_FAIL";

export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAIL = "UPDATE_POST_FAIL";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAIL = "DELETE_POST_FAIL";

export const PUBLIC_SAVE_DRAFT = "PUBLIC_SAVE_DRAFT";

export const RESET_STATE = "RESET_STATE";

import { resourceCache } from '@helpers/requests/resourceCache';
import ResourceException from '@exceptions/resourceException';
import axios from 'axios';

// suspenseを使うためのdata読み取り関数
// cacheされるので直接呼べばpreloadできる
// apiFnはpromiseを返すこと
export const dataFetcher = (apiFn, option) => {
  const apiFnCache = resourceCache(apiFn);
  const cachedResource = apiFnCache.get(option);

  // todo 時間をキャッシュしてキャッシュの管理する
  // cacheがあればcacheを返す
  if (cachedResource) {
    return cachedResource;
  }

  let data;
  let status = 'init';
  let error;

  const fetchingPromise = apiFn(option)
    .then((result) => {
      data = result;
      status = 'done';
    })
    .catch((err) => {
      error = err;
      status = 'error';
    });

  // the return
  function dataFetcherFn(modifier) {
    if (status === 'init') {
      throw fetchingPromise;
    } else if (status === 'error') {
      if (axios.isCancel(error)) {
        error = {
          code: '400',
          message: 'request canceled',
        };
      }
      if (error.response) {
        error = error.response;
      } else if (error.request) {
        error = error.request;
      }
      throw new ResourceException(
        error?.message ? error.message : 'Unknown Error',
        error?.status ? error.status : 400
      );
    }

    return typeof modifier === 'function' ? modifier(data) : data;
  }

  apiFnCache.set(dataFetcherFn, option);

  return dataFetcherFn;
};

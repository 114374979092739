import React, { createContext, useEffect, useRef, useState } from 'react';
import useRequest from '@hooks/useRequest';
import hash from 'object-hash';
const initialState = {
  groups: null,
  unReadGroups: null,
};

export const ChatContext = createContext(initialState);
const { Provider } = ChatContext;

export const ChatProvider = ({ children }) => {
  const [isLoadingChatGroups, setIsLoadingChatGroups] = useState(true);
  const [isErrorChatGroups, setIsErrorChatGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [unReadGroups, setUnReadGroups] = useState([]);
  const groupRequest = useRequest('/v1/chats/groups');
  const groupRequestRef = useRef(() => {}, []);

  // 新着ルームの比較
  const updateGroups = (newGroups) => {
    // 未読メッセージが存在するメッセージグループを抽出
    const newUnReadGroups = newGroups.filter((item) => {
      // チャットグループ配列が存在しない場合は、処理終了
      if (!Array.isArray(item?.chatGroups) || item?.chatGroups?.length < 1)
        return false;

      // チャットグループ配列を探索して、unreadCountが1以上のデータを検索
      const ret = item.chatGroups.filter((chatGroup) => {
        if (!chatGroup.unreadCount || chatGroup.unreadCount < 1) return false;
        return true;
      });

      // 一つ以上、unreadCountが1以上のデータがあれば、trueを返却
      return ret.length > 0 ? true : false;
    });

    //
    // チャットグループに変更があったか？を確認するための、hashリストを作成
    // * チャットグループデータの中に、プロジェクト情報が存在し、その中に毎回更新される値が存在するため、プロジェクト情報を除外したデータでhash値を作成する
    // * 毎回更新される値1 プロジェクト担当者情報のlogined_atのデータ > ログイン中の担当者がいた場合は、チャットデータを取得する度に更新があると判定されてしまう
    // * 毎回更新される値2 チャットグループのreadAtのデータ > チャットを閲覧中の時、毎回最終購読日が更新されるので、、チャットデータを取得する度に更新があると判定されてしまう
    //
    const hashList = {
      groups: hash(
        groups
          .filter((item) => item?.chatGroups)
          .map((item) => {
            return item.chatGroups.map((chatGroup) => {
              // eslint-disable-next-line no-unused-vars
              const { project, ...copyGroup } = chatGroup;
              copyGroup.readAt = null;
              return copyGroup;
            });
          })
      ),
      newGroups: hash(
        newGroups
          .filter((item) => item?.chatGroups)
          .map((item) => {
            return item.chatGroups.map((chatGroup) => {
              // eslint-disable-next-line no-unused-vars
              const { project, ...copyGroup } = chatGroup;
              copyGroup.readAt = null;
              return copyGroup;
            });
          })
      ),
      unReadGroups: hash(
        unReadGroups
          .filter((item) => item?.chatGroups)
          .map((item) => {
            return item.chatGroups.map((chatGroup) => {
              // eslint-disable-next-line no-unused-vars
              const { project, ...copyGroup } = chatGroup;
              return copyGroup;
            });
          })
      ),
      newUnReadGroups: hash(
        newUnReadGroups
          .filter((item) => item?.chatGroups)
          .map((item) => {
            return item.chatGroups.map((chatGroup) => {
              // eslint-disable-next-line no-unused-vars
              const { project, ...copyGroup } = chatGroup;
              return copyGroup;
            });
          })
      ),
    };

    //
    // 保存済みメッセージグループ情報と、最新のメッセージグループ情報を比較
    // → 差分がある場合は、最新のメッセージグループ情報でステートメントを更新
    //
    // console.log(hashList.groups)
    // console.log(hashList.newGroups)
    if (hashList.groups != hashList.newGroups) {
      setGroups(newGroups);
    }

    //
    // 保存済み未読があるメッセージグループ情報と、最新の未読があるメッセージグループ情報を比較
    // → 差分がある場合は、最新の未読があるメッセージグループ情報でステートメントを更新
    //
    if (hashList.unReadGroups != hashList.newUnReadGroups) {
      setUnReadGroups(newUnReadGroups);
    }
  };

  // group取得callbackの定義
  groupRequestRef.current = async () => {
    try {
      const res = await groupRequest.get({ limit: 1000, page: 1 });

      //
      // API側でソートされているので、js側で行わない
      // const sortedGroups = res.data.sort((a, b) =>
      //   dayjs(b.updatedAt).diff(a.updatedAt)
      // )
      //

      updateGroups(res.data);
      setIsErrorChatGroups(false);
      setIsLoadingChatGroups(false);
    } catch (e) {
      setIsErrorChatGroups(true);
      setIsLoadingChatGroups(false);
    }
  };

  // roomの定期取得
  useEffect(() => {
    const id = setInterval(() => {
      getGroups();
    }, 60000);
    return () => clearInterval(id);
  }, []);

  const getGroups = async () => {
    //
    // ログイン前の時は、処理終了
    // Providerのためか、useContextが使用してきないので、location.hrefで対応
    // ※ これをやらないと、firefoxの時に画面が固まる
    //
    if (window.location.pathname.startsWith('/account')) return;

    // ログイン後の時は、チャットグループ取得
    groupRequestRef.current && groupRequestRef.current();
  };

  return (
    <Provider
      value={{
        isErrorChatGroups,
        isLoadingChatGroups,
        unReadGroups,
        groups,
        getGroups,
      }}
    >
      {children}
    </Provider>
  );
};

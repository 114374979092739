// ページネーション設定: 表示件数
export const projectPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const projectPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: 'プロジェクト公開日', value: 'publishedAt' },
    { label: 'プロジェクト開始日', value: 'startedFrom' },
    { label: '募集締切日', value: 'expiredOn' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const projectPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  publishedAt: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
  startedFrom: {
    options: [
      { label: '早い', value: 'ASC' },
      { label: '遅い', value: 'DESC' },
    ],
  },
  expiredOn: {
    options: [
      { label: '早い', value: 'ASC' },
      { label: '遅い', value: 'DESC' },
    ],
  },
};

// ページネーション設定: 表示件数
export const joinedContractProjectPaginationPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const joinedContractProjectPaginationSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '作成時間', value: 'id' },
    { label: 'ステータス', value: 'status' },
    { label: '応募形式', value: 'referenceType' },
    { label: '募集締切', value: 'expiredOn' },
    { label: '報酬/給料', value: 'price' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const joinedContractProjectPaginationSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  id: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
  status: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  referenceType: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  expiredOn: {
    options: [
      { label: '早い', value: 'ASC' },
      { label: '遅い', value: 'DESC' },
    ],
  },
  price: {
    options: [
      { label: '少ない', value: 'ASC' },
      { label: '多い', value: 'DESC' },
    ],
  },
};

// ページネーション設定: 表示件数
export const joinedOrdrerProjectPaginationPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const joinedOrdrerProjectPaginationSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '作成時間', value: 'id' },
    { label: 'ステータス', value: 'status' },
    { label: '募集締切', value: 'expiredOn' },
    { label: '報酬/給料', value: 'price' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const joinedOrdrerProjectPaginationSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  id: {
    options: [
      { label: '新しい', value: 'DESC' },
      { label: '古い', value: 'ASC' },
    ],
  },
  status: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  expiredOn: {
    options: [
      { label: '早い', value: 'ASC' },
      { label: '遅い', value: 'DESC' },
    ],
  },
  price: {
    options: [
      { label: '少ない', value: 'ASC' },
      { label: '多い', value: 'DESC' },
    ],
  },
};

// ページネーション設定: 表示件数
export const joinedProjectRecruitPaginatinPerTypes = {
  label: '表示件数',
  options: [
    // { label: '1', value: 1 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const joinedProjectRecruitPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '応募形式', value: 'referencetype' },
    { label: 'ステータス', value: 'status' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const joinedProjectRecruitPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  default: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  status: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
  referenceType: {
    options: [
      { label: '降順', value: 'DESC' },
      { label: '昇順', value: 'ASC' },
    ],
  },
};

// 最終的に、詳細画面で報酬/給料を表示する用のラベルと同じになってしまったが、一応マージはせず両方のこし
export const priceTypes = {
  label: '報酬/給料の単位',
  options: [
    { label: '時間', value: 'HOURLY' },
    { label: '日', value: 'DAILY' },
    { label: '月', value: 'MONTHLY' },
    { label: '年', value: 'YEARLY' },
    { label: '固定', value: 'CONTINGENCY_FEE' },
    { label: '応相談', value: 'OTHER' },
    { label: '単価（100%稼働時）', value: 'FULL_OPERATION' },
  ],
};

// 最終的に、セレクター用のラベルと同じになってしまったが、一応マージはせず両方のこし
export const priceUnitTypes = {
  label: '報酬/給料の単位',
  options: [
    { label: '時間', value: 'HOURLY' },
    { label: '日', value: 'DAILY' },
    { label: '月', value: 'MONTHLY' },
    { label: '年', value: 'YEARLY' },
    { label: '固定', value: 'CONTINGENCY_FEE' },
    { label: '応相談', value: 'OTHER' },
    { label: '月', value: 'FULL_OPERATION' },
  ],
};

export const interviewTypes = {
  label: '面談形式',
  options: [
    { label: '対面', value: 'FACE_TO_FACE' },
    { label: 'オンライン', value: 'REMOTE' },
  ],
};

export const workTypes = {
  label: '働き方',
  options: [
    { label: '常駐', value: 'ONSITE' },
    { label: '一部リモート', value: 'REMOTE' },
    { label: 'フルリモート', value: 'FULL_REMOTE' },
  ],
};

export const projectTypes = {
  label: '商流',
  options: [
    {
      label: 'クライアント',
      value: 'CLIENT',
      description:
        'エンドユーザーの立場としてあなたの会社や個人としてのプロジェクトにおいて人材を募集する場合',
    },
    {
      label: 'プライム',
      value: 'PRIME',
      description:
        'クライアントとなる企業もしくは個人と元請けの立場として直接契約をしている。また、あなたの会社に所属する人材(または、あなた自身)が参画しており、その現場の増員や交代要員で人材を募集する場合。',
    },
    {
      label: 'エージェント',
      value: 'AGENT',
      description:
        'クライアントやプライム/その他の立場の企業/個人から依頼をうけて、人材の仲介のみをする場合',
    },
    {
      label: 'その他',
      value: 'OTHER',
      description:
        'プライムから請け負った業務の遂行において、あなたの会社に所属する人材(または、あなた自身)が参画しており、その現場の増員や交代要員で人材を募集する場合。',
    },
  ],
};

export const priceEvaluateTypes = {
  label: '報酬/給料の比較条件',
  options: [
    { label: '以上', value: 'lt' },
    { label: '以下', value: 'gt' },
  ],
};

export const participationTypes = {
  label: '想定参画形態',
  options: [
    // { label: '法人', value: 'BUSINESS' },
    { label: 'B2B', value: 'BUSINESS' },
    { label: '正社員', value: 'GENERAL' },
    { label: '副業', value: 'SIDE_BUSINESS' },
    { label: 'フリーランス/個人事業主', value: 'FREELANCE' },
    { label: 'インターン', value: 'INTERN' },
    { label: 'その他（契約社員、アルバイト等）', value: 'OTHER' },
  ],
};

export const statuses = {
  label: 'ステータス',
  options: [
    { label: '公開', value: 'ACTIVE' },
    { label: '予定公開', value: 'RESERVE' },
    { label: '下書き', value: 'DRAFT' },
    { label: '非公開', value: 'INACTIVE' },
    { label: '掲載終了', value: 'COMPLETED' },
  ],
};

export const recruitStatuses = {
  label: '採用ステータス',
  options: [
    { label: '返信待ち', value: 'WAITING' },
    { label: '進行中', value: 'IN_PROGRESS' },
    { label: 'アサイン', value: 'RECRUITED' },
    { label: '見送り', value: 'REJECTED' },
    { label: '辞退', value: 'DECLINED' },
  ],
};

export const projectOwnerTypes = {
  label: 'プロジェクトオーナータイプ',
  options: [
    { label: '企業', value: 'COMPANY' },
    { label: '個人', value: 'USER' },
  ],
};

export const workAverageTypes = {
  label: '希望稼働率',
  options: [
    { label: 'スポット/インタビュー', value: 'SPOT' },
    { label: '週1〜週2日', value: 'W1-2' },
    { label: '週3〜週5日', value: 'W3-5' },
    { label: '応相談', value: 'CONSULTATION' },
  ],
};

export const contractTypes = {
  label: '契約形態',
  options: [
    { value: '1', label: '正社員' },
    { value: '2', label: 'アルバイト' },
    { value: '3', label: 'パート' },
    { value: '4', label: '派遣社員' },
    { value: '5', label: 'インターン' },
    { value: '6', label: '業務委託' },
    { value: '7', label: '請負' },
    { value: '8', label: '嘱託社員' },
  ],
};

export const relateTypes = {
  label: '関連タイプ',
  options: [
    { label: '受注', value: 'CONTRACTOR' },
    { label: '発注', value: 'ORDERER' },
  ],
};

export const referenceTypes = {
  label: '流入元',
  options: [
    { label: 'スカウト', value: 'SCOUT' },
    { label: '応募', value: 'SUBSCRIBE' },
  ],
};

// 画面上に表示する有料オプションｓセレクター
export const stickerTypes = {
  label: 'オプション',
  options: [{ label: '急募', value: 'WANTED' }],
};

export const projectScheduledAtTimeTypes = {
  label: '時間',
  options: [...Array(24)].map((item, index) => {
    return {
      label: `${('00' + String(index)).slice(-2)}:00`,
      value: `${('00' + String(index)).slice(-2)}:00`,
    };
  }),
};

export const publishScopeTypes = {
  label: '公開範囲タイプ',
  options: [
    { label: '誰でも', value: 'ANY_ONE' },
    { label: 'メンバーのみ', value: 'MEMBER_ONLY' },
  ],
};

export const publishScopeRangeTypes = {
  label: '公開範囲メンバータイプ',
  options: [
    { label: '社員', value: 'REGULAR' },
    { label: 'アルムナイ', value: 'ALUMNI' },
    { label: '外部パートナー', value: 'COLLABORATOR' },
  ],
};

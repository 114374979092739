import React, { useEffect, useContext } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';
import { mapKeysCamelCase } from '@helpers/mapKeyConvert';
import { allFlattenRoutes as routes } from './index';
import { AuthContext } from '@contexts/authContext';
import Loader from '@components/Loader';
import { localstorageKeys } from '@constants/other';
import { regexUrlAuthCode } from '@constants/regex';
import * as utilities from '@helpers/utilities';

// -------------------------------------------
// 使用レイアウトの設定
// -------------------------------------------
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

const AuthLayout = Loadable({
  loader: () => import('@layouts/Auth'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const BlankLayout = Loadable({
  loader: () => import('@layouts/Blank'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const NoLoginLayout = Loadable({
  loader: () => import('@layouts/NoLogin'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const BaseLayout = Loadable({
  loader: () => import('@layouts/Base'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

// -------------------------------------------
// レンダリング対象のレイアウト判定
// -------------------------------------------
const Layout = ({ children, ...rest }) => {
  const { authState, isLoaded } = useContext(AuthContext);
  const location = useLocation();

  // URLが変わったら、スクロールをトップ位置に戻す
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [location.pathname]);

  // URLパラメーターにuuAuthCodeが設定されていたら、ローカルストレージに保存する
  useEffect(() => {
    // URLパラメーター取得
    const queries = mapKeysCamelCase(
      utilities.parseQueryString(location.search)
    );

    // URL招待用コードが存在しない、不正な値の場合は処理終了
    if (
      typeof queries?.uuAuthCode != 'string' ||
      !queries?.uuAuthCode.match(regexUrlAuthCode)
    )
      return;

    // ローカルストレージにuuAuthCodeを保存
    const storageData = utilities.getLocalStorageItem(localstorageKeys.default);
    storageData.uuAuthCode = queries.uuAuthCode;
    utilities.setLocalStorageItem(localstorageKeys.default, storageData);
  }, []);

  // ------------------------------------------
  // 1. 使用レイアウト判定フラグの初期化
  // ------------------------------------------
  // ログイン系画面のパスか？検証
  const isAuth =
    location.pathname.startsWith('/account') &&
    !location.pathname.startsWith('/account/register');

  // 非ログイン状態か？検証
  const isNoLogin =
    !authState.isSignUp ||
    !authState.isSignIn ||
    !authState.isRegistered ||
    location.pathname.startsWith('/account/register');

  const isPublicContent =
    location.pathname.startsWith('/pricing') ||
    location.pathname.startsWith('/contact') ||
    location.pathname.startsWith('/privacy-policy') ||
    location.pathname.startsWith('/terms') ||
    location.pathname.startsWith('/public-posts') ||
    location.pathname.startsWith('/trade');

  const isSync = location.pathname.startsWith('/sync');

  // ------------------------------------------
  // 2. 使用レイアウトコンポーネント出力
  // ------------------------------------------
  //
  // authContextが1回もinit処理を完了していない場合は、画面を返さない
  // ※ authContextがinitを開始して、loadingフラグを設定する前に、実行される事が多いので対策しています
  //
  if (!isLoaded) return <Loader></Loader>;
  // ログインが必要のないコンテンツの場合

  // 「account/」以下のコンテンツ（サインイン、ログイン、ログアウト、初回登録）
  if (isAuth) {
    return <AuthLayout {...rest}>{children}</AuthLayout>;
  }

  // localStorageの同期
  if (isSync) {
    return <BlankLayout {...rest}>{children}</BlankLayout>;
  }

  // サインイン && ログイン && 初回設定が完了していない場合
  if (isNoLogin) {
    return <NoLoginLayout {...rest}>{children}</NoLoginLayout>;
  }

  // サインイン && ログイン && 初回設定が完了していない && 公開コンテンツにアクセスした場合
  // if (isPublicContent) {
  if (isNoLogin && isPublicContent) {
    return <NoLoginLayout {...rest}>{children}</NoLoginLayout>;
  }

  return <BaseLayout {...rest}>{children}</BaseLayout>;
};

// -------------------------------------------
// レイアウトコンポーネント出力
// -------------------------------------------
const App = (props) => {
  // レイアウト選択関数にコンテンツを設定して画面出力
  return (
    <Layout {...props}>
      <Switch>
        {routes.map((route, index) => {
          return (
            !route.children && (
              <route.route
                key={index}
                path={route.path}
                roles={route.roles}
                exact={route.exact}
                title={
                  route.title
                    ? `PJDB | ${route.title}`
                    : 'PJDB | プロジェクト単位で働く、これからの働き方'
                }
                component={route.component}
                // render={() => route.component}
              ></route.route>
            )
          );
        })}
      </Switch>
    </Layout>
  );
};

export default App;

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { script } from '../requests/fileResource';
const stub = () => {
  throw new Error('forgot to wrap provider');
};

const initState = {
  createPartsElement: stub,
  elements: null,
  submit: stub,
};
export const PaymentContext = createContext(initState);
export const PaymentConsumer = PaymentContext.Consumer;

export const PaymentProvider = ({ children }) => {
  const [payjp, setPayjp] = useState(null);
  const [elements, setElements] = useState(null);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    const init = async () => {
      await script('https://js.pay.jp/v2/pay.js');
      const payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY);
      setPayjp(payjp);
      const elements = payjp.elements();
      setElements(elements);
      payjp.retrieveAvailability().then((response) => {
        if (!response.error) {
          setBrand(response);
        }
      });
    };
    init();
  }, []);

  /**
   * カードトークンの取得処理を実行
   * @param {*} numberElement
   * @return {*}
   */
  const submit = async (numberElement) => {
    return await payjp
      .createToken(numberElement)
      .then((res) => {
        // レスポンスにカードトークンが含まれない場合 (* Payjpの障害時以外はありえない
        if (!res.id || res.error) {
          return {
            status: false,
            token: null,
            error: res.error,
          };
        }
        // 作成したカードトークン情報を返却
        return {
          status: true,
          token: res.id,
          error: null,
        };
      })
      .catch((_e) => {
        // 入力したカード情報に問題があり、カードトークンが取得出来なかった場合
        return {
          status: false,
          token: null,
          error: {
            status: null,
            message: 'カード情報の検証に失敗しました',
          },
        };
      });
  };

  /**
   * カード情報入力要素の作成処理
   * @return {*}
   */
  const createPartsElement = useCallback(() => {
    if (elements._elements.length > 0) {
      const ret = {
        cvc: null,
        expiry: null,
        number: null,
        brand: brand,
      };

      elements._elements.forEach((el) => {
        if (el._componentName == 'cardNumber') {
          ret.number = el;
        }
        if (el._componentName == 'cardExpiry') {
          ret.expiry = el;
        }
        if (el._componentName == 'cardCvc') {
          ret.cvc = el;
        }
      });
      return ret;
    }

    const elementStyle = {
      base: {
        color: '#333',
        fontSize: '14px',
      },
    };

    return {
      //card: elements.create('card'),
      brand: brand,
      cvc: elements.create('cardCvc', {
        style: elementStyle,
        placeholder: '123',
      }),

      expiry: elements.create('cardExpiry', {
        style: elementStyle,
        placeholder: 'MM / YY',
      }),

      number: elements.create('cardNumber', {
        style: elementStyle,
        placeholder: '0000 0000 0000 0000',
      }),
    };
  }, [elements, brand]);

  return (
    <PaymentContext.Provider
      value={{
        createPartsElement,
        elements,
        brand,
        submit,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

// imageを先に読み込む用
export const image = (imageSrc) => {
  return new Promise((resolve, reject) => {
    const file = new Image();
    file.onload = () => {
      resolve(imageSrc);
    };

    file.onerror = reject;

    file.src = imageSrc;
  });
};

//scriptを読み込む用
export const script = (scriptSrc) => {
  return new Promise((resolve, reject) => {
    const file = document.createElement('script');
    file.onload = () => {
      resolve(scriptSrc);
    };

    file.onerror = reject;

    file.src = scriptSrc;

    document.getElementsByTagName('body')[0].appendChild(file);
  });
};

// ローカルストレージキー
export const localstorageKeys = {
  default: '_pjdb_a8y3bkmfw24aku48phmms9pcs3dne69f',
};

// ページネーション設定: 表示件数
export const otherPaginatinPerTypes = {
  label: '表示件数',
  options: [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ],
};

// ページネーション設定: 並び順（キー）
export const otherPaginatinSortByTypes = {
  label: '並び順（キー）',
  options: [
    { label: '作成時間', value: 'createdAt' },
    { label: '更新時間', value: 'updatedAt' },
  ],
};

// ページネーション設定: 並び順（タイプ）
export const otherPaginatinSortOrderTypes = {
  label: '並び順（タイプ）',
  options: [
    { label: '昇順', value: 'ASC' },
    { label: '降順', value: 'DESC' },
  ],
};

export const consultationTypes = {
  label: 'ご相談内容',
  options: [
    { label: '業務提携', value: '業務提携' },
    { label: '不具合について', value: '不具合について' },
    { label: 'その他のご相談', value: 'その他のご相談' },
  ],
};

export const searchTypes = {
  label: '検索対象',
  options: [
    // 一括検索のAPIと画面が出来るまではAllは表示しない
    // { label: '全て', value: 'all' },
    { label: 'プロジェクト', value: 'project' },
    { label: 'ユーザー', value: 'user' },
    { label: '会社', value: 'company' },
  ],
};

export const notificationTypes = {
  label: '通知タイプ',
  options: [
    { label: 'サービス通知', value: 'SERVICE' },
    { label: 'システム通知', value: 'SYSTEM' },
  ],
};

import querystring from 'querystring';
import httpBuildQuery from 'http-build-query';
import { mapKeysCamelCase, mapKeysSnakeCase } from '../mapKeyConvert';
import { employeeCountTypes } from '@constants/company';
import {
  interviewTypes,
  participationTypes,
  priceTypes,
  projectTypes,
} from '@constants/project';
import { userWorkStatusTypes } from '@constants/user';

export const parseQueryString = (
  queryAsString: string,
  isFilter: boolean,
  filterList: Array
) => {
  if (
    !queryAsString ||
    typeof queryAsString != 'string' ||
    queryAsString == ''
  ) {
    return {};
  }

  // 先頭に?がついている場合は削除
  if (queryAsString.startsWith('?', 0)) {
    queryAsString = queryAsString.slice(1);
  }

  // クエリ文字列をパース
  const ret = querystring.parse(queryAsString);

  // Filterフラグがtrueではない場合は、パース結果を返却
  if (!isFilter) {
    return ret;
  }

  // 空パラメーターを除外した結果を返却
  return Object.keys(querystring.parse(queryAsString))
    .filter((item) => {
      // フィルタリング処理1: 空文字のキーは削除
      if (!ret[item] || ret[item] == '') {
        return false;
      }

      // フィルタリング処理1: フィルターリストに指定されているキーは削除
      if (
        filterList &&
        Array.isArray(filterList) &&
        filterList.indexOf(item) != -1
      ) {
        return false;
      }

      return true;
    })
    .reduce((acc, value) => {
      acc[value] = ret[value];
      return acc;
    }, {});
};

export const buildQueryString = (queries) => {
  if (!queries || typeof queries != 'object') {
    return '';
  }
  return httpBuildQuery(queries);
};

export const getSearchParameterEmployeeCountType = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) return null;

  // 最小値配列を作成
  const minValues = data
    .filter((item) => {
      return employeeCountTypes.options.find((e) => e.value == item)
        ? true
        : false;
    })
    .map((item) => {
      const employeeCountType = employeeCountTypes.options.find(
        (e) => e.value == item
      );
      return employeeCountType.min;
    });

  // 最大値配列を作成
  const maxValues = data
    .filter((item) => {
      return employeeCountTypes.options.find((e) => e.value == item)
        ? true
        : false;
    })
    .map((item) => {
      const employeeCountType = employeeCountTypes.options.find(
        (e) => e.value == item
      );
      return employeeCountType.max;
    });

  // 最小値配列を昇順ソート
  minValues.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  // 最大値配列を降順ソート
  maxValues.sort((a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  });

  // 最大値配列に-1が含まれている場合は、上限なしフラグを設定
  const isWithoutLimit = maxValues.find((item) => item == -1) ? true : false;

  // 最小値、最大値の実数オブジェクトを返却
  return {
    max: !isWithoutLimit && maxValues.length > 0 ? maxValues[0] : null,
    min: minValues.length > 0 ? minValues[0] : null,
  };
};

export const getSearchParameterArrayTypeValue = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) return '';
  return data.join(',');
};

export const getSearchParameterMultipleArrayTypeValue = (data) => {
  if (!data || !Array.isArray(data) || data.length < 1) return [];

  const ret = data.reduce((acc, value) => {
    if (!value || !Array.isArray(value) || value.length < 1) return acc;
    const currentArray = value
      .filter((item) => item.value)
      .map((item) => item.value);
    return [...acc, ...currentArray];
  }, []);

  // 重複値を除外した配列を返却
  return [...new Set(ret)];
};

// 検索クエリ文字列作成: 会社検索
export const getSearchCompanyQuery = (data) => {
  const employeeCountValues = getSearchParameterEmployeeCountType(
    data.employeeCountType
  );
  // プロジェクト検索パラメーター作成
  let params = {
    keyword: data.keyword ? data.keyword : '',
    employeeCountType: getSearchParameterArrayTypeValue(data.employeeCountType),
    employeeCountMin:
      employeeCountValues && employeeCountValues.min
        ? employeeCountValues.min
        : null,
    employeeCountMax:
      employeeCountValues && employeeCountValues.max
        ? employeeCountValues.max
        : null,
    locationIds: getSearchParameterMultipleArrayTypeValue(data.locationIds),
  };

  //
  // 作成したパラメーターのキー名をスネークケースに変換
  // クエリ文字列は、検索結果画面でURL文字列として取り扱うので、ここでスネークケースに変換しておく必要がある
  // axios.option.paramsに付与しないので、一括変換の処理を通過しないため
  //
  params = mapKeysSnakeCase(params);

  // 作成したパラメーターから、検索クエリを作成
  let queryAsString = buildQueryString(params);

  // 検索クエリから空値を除外したパラメーターリスト作成
  let tmp = parseQueryString(queryAsString, true);

  // 空値を除外したパラメーターから、検索クエリを作成
  queryAsString = buildQueryString(tmp);

  return queryAsString;
};

// 検索クエリ文字列作成: プロジェクト検索
export const getSearchProjectQuery = (data) => {
  // プロジェクト検索パラメーター作成
  let params = {
    categoryIds: getSearchParameterMultipleArrayTypeValue(data.categoryIds),
    finishedBy: data.finishedBy ? data.finishedBy : '',
    interviewType: getSearchParameterArrayTypeValue(data.interviewType),
    keyword: data.keyword ? data.keyword : '',
    locationIds: getSearchParameterMultipleArrayTypeValue(data.locationIds),
    participationType: getSearchParameterArrayTypeValue(data.participationType),
    priceAmountMax: data.priceAmountMax
      ? String(data.priceAmountMax).split(',').join('')
      : '',
    priceAmountMin: data.priceAmountMin
      ? String(data.priceAmountMin).split(',').join('')
      : '',
    projectOwner: data.projectOwner ? data.projectOwner : '',
    priceType:
      data.priceType && data.priceType.value ? data.priceType.value : '',
    projectType: getSearchParameterArrayTypeValue(data.projectType),
    workType: getSearchParameterArrayTypeValue(data.workType),
    skillCategoryIds: getSearchParameterMultipleArrayTypeValue(
      data.skillCategoryIds
    ),
    startedFrom: data.startedFrom ? data.startedFrom : '',
    stickerLabels: data.isWanted ? ['WANTED'] : [],
  };

  //
  // 作成したパラメーターのキー名をスネークケースに変換
  // クエリ文字列は、検索結果画面でURL文字列として取り扱うので、ここでスネークケースに変換しておく必要がある
  // axios.option.paramsに付与しないので、一括変換の処理を通過しないため
  //
  params = mapKeysSnakeCase(params);

  // 作成したパラメーターから、検索クエリを作成
  let queryAsString = buildQueryString(params);

  // 検索クエリから空値を除外したパラメーターリスト作成
  let tmp = parseQueryString(queryAsString, true);

  // 空値を除外したパラメーターから、検索クエリを作成
  queryAsString = buildQueryString(tmp);

  return queryAsString;
};

// 検索クエリ文字列作成: ユーザー検索
export const getSearchUserQuery = (data) => {
  // プロジェクト検索パラメーター作成
  let params = {
    keyword: data.keyword ? data.keyword : '',
    locationIds: getSearchParameterMultipleArrayTypeValue(data.locationIds),
    skillCategoryIds: getSearchParameterMultipleArrayTypeValue(
      data.skillCategoryIds
    ),
    workingStatus: getSearchParameterArrayTypeValue(data.workingStatus),
  };

  //
  // 作成したパラメーターのキー名をスネークケースに変換
  // クエリ文字列は、検索結果画面でURL文字列として取り扱うので、ここでスネークケースに変換しておく必要がある
  // axios.option.paramsに付与しないので、一括変換の処理を通過しないため
  //
  params = mapKeysSnakeCase(params);

  // 作成したパラメーターから、検索クエリを作成
  let queryAsString = buildQueryString(params);

  // 検索クエリから空値を除外したパラメーターリスト作成
  let tmp = parseQueryString(queryAsString, true);

  // 空値を除外したパラメーターから、検索クエリを作成
  queryAsString = buildQueryString(tmp);

  return queryAsString;
};

// 検索クエリ文字列作成: 会社検索
export const getInitialSearchParameter = (
  data,
  categories,
  skillCategories,
  locations
) => {
  // クエリパラメーターのパース
  const queries = mapKeysCamelCase(parseQueryString(data));

  // 検索パラメーターオブジェクトを初期化
  const parameters = {
    categoryIds: [],
    employeeCountType: [],
    finishedBy: queries.finishedBy ? queries.finishedBy : null,
    interviewType: [],
    isWanted: false,
    keyword: queries.keyword ? queries.keyword : '',
    locationIds: [],
    searchType: null,
    priceAmountMax: queries.priceAmountMax ? queries.priceAmountMax : null,
    priceAmountMin: queries.priceAmountMin ? queries.priceAmountMin : null,
    participationType: [],
    priceType:
      queries.priceType &&
      priceTypes.options.find((e) => e.value == queries.priceType)
        ? priceTypes.options.find((e) => e.value == queries.priceType)
        : null,
    projectOwner: queries.projectOwner ? queries.projectOwner : '',
    projectType: [],
    skillCategoryIds: [],
    startedFrom: queries.startedFrom ? queries.startedFrom : null,
    workingStatus: [],
    workType: [],
  };

  // 特殊送信値（カンマ区切り文字列）を選択値に変換: 社員規模の送信値を
  const splitEmployeeCountTypeString = queries.employeeCountType
    ? String(queries.employeeCountType).split(',')
    : null;
  if (
    splitEmployeeCountTypeString &&
    Array.isArray(splitEmployeeCountTypeString) &&
    splitEmployeeCountTypeString.length > 0
  ) {
    parameters.employeeCountType = splitEmployeeCountTypeString.reduce(
      (acc, value) => {
        const item = employeeCountTypes.options.find((e) => e.value == value);
        if (item) acc.push(item);
        return acc;
      },
      []
    );
  }

  // 特殊送信値（カンマ区切り文字列）を選択値に変換: 稼働可否
  const splitWorkingStatusString = queries.workingStatus
    ? String(queries.workingStatus).split(',')
    : null;
  if (
    splitWorkingStatusString &&
    Array.isArray(splitWorkingStatusString) &&
    splitWorkingStatusString.length > 0
  ) {
    parameters.workingStatus = splitWorkingStatusString.reduce((acc, value) => {
      const item = userWorkStatusTypes.options.find((e) => e.value == value);
      if (item) acc.push(item);
      return acc;
    }, []);
  }

  // 特殊送信値（カンマ区切り文字列）を選択値に変換: 面談形式
  const splitInterviewTypeString = queries.interviewType
    ? String(queries.interviewType).split(',')
    : null;
  if (
    splitInterviewTypeString &&
    Array.isArray(splitInterviewTypeString) &&
    splitInterviewTypeString.length > 0
  ) {
    parameters.interviewType = splitInterviewTypeString.reduce((acc, value) => {
      const item = interviewTypes.options.find((e) => e.value == value);
      if (item) acc.push(item);
      return acc;
    }, []);
  }

  // 特殊送信値（カンマ区切り文字列）を選択値に変換: 想定参画タイプ
  const splitParticipationTypeString = queries.participationType
    ? String(queries.participationType).split(',')
    : null;
  if (
    splitParticipationTypeString &&
    Array.isArray(splitParticipationTypeString) &&
    splitParticipationTypeString.length > 0
  ) {
    parameters.participationType = splitParticipationTypeString.reduce(
      (acc, value) => {
        const item = participationTypes.options.find((e) => e.value == value);
        if (item) acc.push(item);
        return acc;
      },
      []
    );
  }

  // 特殊送信値（カンマ区切り文字列）を選択値に変換: 商流
  const splitProjectTypeString = queries.projectType
    ? String(queries.projectType).split(',')
    : null;
  if (
    splitProjectTypeString &&
    Array.isArray(splitProjectTypeString) &&
    splitProjectTypeString.length > 0
  ) {
    parameters.projectType = splitProjectTypeString.reduce((acc, value) => {
      const item = projectTypes.options.find((e) => e.value == value);
      if (item) acc.push(item);
      return acc;
    }, []);
  }

  // プロジェクトカテゴリー、スキルカテゴリー、地域カテゴリーの送信値配列初期化
  const selectCategoryIds = [];
  const selectSkillCategoryIds = [];
  const selectLocationIds = [];

  // 配列パラメーターの送信値抽出
  for (let index in queries) {
    // ステッカーラベルにWANTEDが設定されている場合は、該当パラメーターを設定
    if (index.startsWith('stickerLabels') && queries[index] == 'WANTED') {
      parameters.isWanted = true;
      continue;
    }

    // プロジェクトカテゴリーID
    if (index.startsWith('categoryIds')) {
      selectCategoryIds.push(queries[index]);
      continue;
    }

    // スキルカテゴリーID
    if (index.startsWith('skillCategoryIds')) {
      selectSkillCategoryIds.push(queries[index]);
      continue;
    }

    // 地域ID
    if (index.startsWith('locationIds')) {
      selectLocationIds.push(queries[index]);
      continue;
    }
  }

  // 特殊送信値（配列パラメーター）を選択値に変換: プロジェクトカテゴリーID
  if (categories && Array.isArray(categories) && categories.length > 0) {
    parameters.categoryIds = categories.reduce((acc, value) => {
      if (!value.options || !Array.isArray(value.options)) return acc;
      const selected = value.options.filter((item) => {
        return selectCategoryIds.find((e) => String(e) == String(item.value));
      });
      acc.push(selected);
      return acc;
    }, []);
  }

  // 特殊送信値（配列パラメーター）を選択値に変換: スキルカテゴリーID
  if (
    skillCategories &&
    Array.isArray(skillCategories) &&
    skillCategories.length > 0
  ) {
    parameters.skillCategoryIds = skillCategories.reduce((acc, value) => {
      if (!value.options || !Array.isArray(value.options)) return acc;
      const selected = value.options.filter((item) => {
        return selectSkillCategoryIds.find(
          (e) => String(e) == String(item.value)
        );
      });
      acc.push(selected);
      return acc;
    }, []);
  }

  // 特殊送信値（配列パラメーター）を選択値に変換: 地域ID
  if (locations && Array.isArray(locations) && locations.length > 0) {
    parameters.locationIds = locations.reduce((acc, value) => {
      if (!value.options || !Array.isArray(value.options)) return acc;
      const selected = value.options.filter((item) => {
        return selectLocationIds.find((e) => String(e) == String(item.value));
      });
      acc.push(selected);
      return acc;
    }, []);
  }

  return parameters;
};

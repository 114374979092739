import Routes from '@routes/Routes';
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// Themes
import './assets/scss/Saas.scss';

flatpickr.localize(Japanese);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default App;
